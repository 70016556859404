<template>
  <svg xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 837 128">
    <path
      fill="#1e1b4b"
      d="
  M 18.00 62.50
  L 18.00 98.25
  A 0.74 0.50 90.0 0 1 17.50 98.99
  L 1.18 98.95
  A 0.70 0.41 90.0 0 1 0.77 98.25
  L 0.76 0.56
  A 0.31 0.31 0.0 0 1 1.07 0.25
  Q 20.66 0.25 40.25 0.25
  C 83.03 0.24 83.42 61.07 41.56 61.86
  Q 30.07 62.08 18.57 62.06
  A 0.57 0.44 -0.0 0 0 18.00 62.50
  Z
  M 40.81 15.69
  Q 33.90 15.22 18.52 15.56
  A 0.53 0.44 -0.7 0 0 18.00 16.00
  L 18.00 46.25
  A 0.53 0.46 -0.0 0 0 18.53 46.71
  C 27.12 46.79 40.70 47.99 47.25 44.99
  C 55.05 41.41 57.02 31.50 54.03 24.14
  Q 50.88 16.37 40.81 15.69
  Z"
    />
    <path
      fill="#1e1b4b"
      d="
  M 508.00 83.25
  L 508.00 15.75
  A 0.50 0.50 0.0 0 0 507.50 15.25
  L 495.25 15.25
  A 0.50 0.50 0.0 0 1 494.75 14.75
  L 494.75 0.75
  A 0.55 0.48 0.0 0 1 495.30 0.27
  C 510.96 0.24 527.48 -0.71 543.00 1.01
  Q 565.07 3.47 572.28 21.68
  Q 574.86 28.21 575.36 40.12
  C 575.85 51.84 576.12 64.34 573.01 75.74
  C 569.60 88.25 556.91 96.65 544.20 98.45
  Q 541.33 98.86 528.40 98.96
  Q 511.74 99.09 495.12 98.96
  A 0.52 0.50 -89.4 0 1 494.63 98.44
  L 494.61 84.74
  A 1.04 0.81 -0.3 0 1 495.64 83.93
  L 507.44 83.73
  A 0.57 0.48 -0.6 0 0 508.00 83.25
  Z
  M 524.76 15.94
  L 524.75 82.75
  A 0.64 0.61 2.6 0 0 525.34 83.36
  C 543.74 84.75 558.48 81.60 558.53 59.75
  C 558.60 32.65 561.66 12.79 525.21 15.62
  A 0.49 0.30 -3.7 0 0 524.76 15.94
  Z"
    />
    <path
      fill="#3730a3"
      d="
  M 630.88 126.69
  C 644.49 84.76 657.69 42.52 672.00 0.84
  A 0.87 0.76 -79.4 0 1 672.75 0.25
  L 687.75 0.25
  A 0.40 0.35 -79.7 0 1 688.06 0.77
  L 646.93 126.68
  A 0.81 0.68 -79.6 0 1 646.25 127.25
  L 631.25 127.25
  A 0.43 0.40 -80.5 0 1 630.88 126.69
  Z"
    />
    <path
      fill="#1e1b4b"
      d="
  M 462.50 29.25
  L 481.25 29.25
  A 0.75 0.50 0.0 0 1 482.00 29.75
  L 482.00 42.25
  A 0.75 0.50 -0.0 0 1 481.25 42.75
  L 462.50 42.75
  A 0.62 0.42 -90.0 0 0 462.08 43.37
  Q 461.86 70.75 462.03 81.98
  C 462.07 84.71 464.12 85.47 466.62 85.48
  Q 472.91 85.50 479.22 85.53
  A 0.55 0.47 -0.0 0 1 479.77 86.00
  L 479.85 98.44
  A 0.53 0.53 89.5 0 1 479.32 98.98
  Q 470.10 99.04 460.76 98.92
  C 448.49 98.77 445.78 91.21 445.76 80.40
  Q 445.73 61.83 445.76 43.23
  A 0.51 0.48 -0.0 0 0 445.25 42.75
  L 428.00 42.75
  A 0.50 0.50 0.0 0 1 427.50 42.25
  L 427.50 29.75
  A 0.50 0.50 0.0 0 1 428.00 29.25
  L 445.25 29.25
  A 0.50 0.50 0.0 0 0 445.75 28.75
  L 445.75 7.25
  A 0.50 0.50 0.0 0 1 446.25 6.75
  L 461.25 6.74
  A 0.76 0.75 90.0 0 1 462.00 7.50
  L 462.00 28.75
  A 0.50 0.50 0.0 0 0 462.50 29.25
  Z"
    />
    <path
      fill="#1e1b4b"
      d="
  M 137.51 50.51
  C 147.71 18.96 197.11 19.54 206.77 51.26
  C 209.30 59.57 208.90 71.46 205.75 79.63
  C 195.93 105.11 156.31 108.94 141.28 85.64
  C 135.25 76.30 134.07 61.17 137.51 50.51
  Z
  M 190.74 53.98
  C 185.57 39.59 164.32 37.54 155.69 49.95
  C 152.62 54.36 151.38 61.66 152.00 66.95
  Q 153.97 83.98 168.29 86.15
  C 180.86 88.05 190.70 81.20 192.13 68.25
  Q 193.01 60.32 190.74 53.98
  Z"
    />
    <path
      fill="#1e1b4b"
      d="
  M 365.72 91.27
  C 364.94 90.25 364.13 89.96 362.97 90.36
  A 0.69 0.67 -9.7 0 0 362.50 91.00
  L 362.50 126.75
  A 0.50 0.50 0.0 0 1 362.00 127.25
  L 346.75 127.25
  A 0.50 0.50 0.0 0 1 346.25 126.75
  L 346.25 29.75
  A 0.50 0.50 0.0 0 1 346.75 29.25
  L 361.50 29.25
  A 0.75 0.50 0.0 0 1 362.25 29.75
  L 362.25 37.75
  A 0.75 0.50 90.0 0 0 362.75 38.50
  L 364.26 38.51
  A 1.03 0.70 -67.6 0 0 364.98 38.04
  C 370.75 29.34 379.49 26.68 389.39 27.39
  C 405.99 28.57 416.97 41.09 418.82 57.51
  C 421.51 81.39 409.22 103.40 382.50 100.84
  Q 372.30 99.87 365.72 91.27
  Z
  M 362.69 58.44
  C 360.25 74.25 367.42 88.16 385.17 86.66
  C 400.09 85.40 404.20 71.36 402.52 58.49
  C 399.59 36.00 366.19 35.73 362.69 58.44
  Z"
    />
    <path
      fill="#1e1b4b"
      d="
  M 837.00 63.06
  L 837.00 68.49
  L 785.13 68.75
  A 0.58 0.58 -3.5 0 0 784.55 69.40
  C 786.43 85.14 802.38 91.51 815.59 83.67
  Q 817.24 82.69 821.20 76.10
  A 0.64 0.52 -60.4 0 1 821.94 75.81
  L 834.15 82.14
  A 0.60 0.50 -62.7 0 1 834.32 82.91
  C 826.88 97.40 812.39 102.95 796.99 100.36
  C 776.57 96.92 766.99 80.81 768.31 60.89
  C 769.80 38.26 787.66 23.14 810.77 28.09
  C 828.37 31.86 836.47 45.90 837.00 63.06
  Z
  M 820.19 55.97
  C 818.03 36.52 788.41 36.55 784.90 55.97
  A 0.46 0.37 -84.2 0 0 785.25 56.50
  L 819.75 56.50
  A 0.48 0.45 87.1 0 0 820.19 55.97
  Z"
    />
    <path
      fill="#1e1b4b"
      d="
  M 242.07 36.92
  L 243.50 36.99
  A 0.99 0.58 -64.2 0 0 244.20 36.52
  C 252.44 24.06 272.35 25.59 280.10 37.92
  C 281.29 39.81 283.07 39.29 284.13 37.56
  C 293.45 22.41 318.58 25.97 324.55 42.41
  Q 326.13 46.78 326.20 57.17
  Q 326.33 77.80 326.23 98.45
  A 0.55 0.48 -90.0 0 1 325.75 99.00
  L 310.50 99.00
  A 0.55 0.49 -90.0 0 1 310.01 98.45
  Q 309.99 77.22 310.00 56.01
  C 310.01 48.68 308.28 42.58 300.24 41.78
  C 285.65 40.32 284.00 51.05 284.00 62.92
  Q 284.00 80.51 284.00 98.38
  A 0.62 0.50 -90.0 0 1 283.50 99.00
  L 268.50 99.00
  A 0.66 0.44 90.0 0 1 268.06 98.34
  Q 267.94 76.88 268.02 55.51
  C 268.08 37.50 246.71 37.03 242.45 51.77
  Q 241.84 53.87 241.84 56.50
  L 241.81 98.25
  A 0.81 0.75 0.0 0 1 241.00 99.00
  L 226.00 99.00
  A 0.75 0.50 90.0 0 1 225.50 98.25
  L 225.50 29.75
  A 0.50 0.50 0.0 0 1 226.00 29.25
  L 241.00 29.25
  A 0.50 0.50 0.0 0 1 241.50 29.75
  L 241.50 36.26
  A 0.66 0.60 -88.6 0 0 242.07 36.92
  Z"
    />
    <path
      fill="#1e1b4b"
      d="
  M 103.00 37.25
  L 104.25 37.25
  A 0.88 0.67 -74.1 0 0 104.93 36.74
  C 108.91 28.57 118.51 28.43 126.64 29.11
  A 0.66 0.64 2.4 0 1 127.25 29.75
  L 127.25 43.00
  A 0.63 0.40 -0.7 0 1 126.63 43.40
  C 117.95 43.53 108.24 42.74 104.29 51.26
  Q 102.72 54.64 102.74 63.89
  Q 102.77 81.22 102.74 98.50
  A 0.50 0.49 -90.0 0 1 102.25 99.00
  L 87.00 99.00
  A 0.75 0.50 90.0 0 1 86.50 98.25
  L 86.50 29.56
  A 0.31 0.30 -0.0 0 1 86.81 29.26
  L 102.00 29.25
  A 0.50 0.50 0.0 0 1 102.50 29.75
  L 102.50 36.75
  A 0.50 0.50 0.0 0 0 103.00 37.25
  Z"
    />
    <path
      fill="#1e1b4b"
      d="
  M 608.75 37.25
  L 610.25 37.25
  A 1.12 0.37 -66.2 0 0 610.92 36.51
  C 614.89 28.46 624.37 28.52 632.47 29.05
  A 0.56 0.44 2.0 0 1 633.00 29.50
  L 633.00 42.75
  A 0.84 0.79 1.0 0 1 632.13 43.54
  C 623.78 43.24 613.44 42.94 609.81 51.80
  Q 608.51 54.97 608.50 65.31
  Q 608.49 81.92 608.50 98.51
  A 0.50 0.49 -0.0 0 1 608.00 99.00
  L 592.75 99.00
  A 0.75 0.50 90.0 0 1 592.25 98.25
  L 592.25 29.75
  A 0.50 0.50 0.0 0 1 592.75 29.25
  L 607.75 29.25
  A 0.50 0.50 0.0 0 1 608.25 29.75
  L 608.25 36.75
  A 0.50 0.50 0.0 0 0 608.75 37.25
  Z"
    />
    <path
      fill="#1e1b4b"
      d="
  M 724.24 87.24
  L 725.93 87.19
  A 1.16 0.31 -74.7 0 0 726.48 86.24
  L 743.30 30.01
  A 1.07 0.97 -80.7 0 1 744.25 29.25
  L 760.25 29.25
  A 0.39 0.33 -79.3 0 1 760.54 29.76
  L 738.11 98.33
  A 0.93 0.50 -76.0 0 1 737.50 99.00
  L 712.75 99.00
  A 0.80 0.73 -8.6 0 1 712.01 98.50
  L 689.48 29.76
  A 0.38 0.36 79.9 0 1 689.81 29.26
  L 705.75 29.25
  A 1.07 1.03 -8.0 0 1 706.77 29.99
  L 723.64 86.83
  A 0.61 0.58 -9.3 0 0 724.24 87.24
  Z"
    />
  </svg>
</template>
